<template>
  <div>
    <div
        variant="light"
        class="justify-content-end container alert alert-white alert-shadow"
        style="padding-top: 10px"
    >
      <div class="row mb-4">
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Tìm kiếm :</label></b-col>
            <b-col>
              <b-form-input
                  v-model="searchData.phone"
                  placeholder="Số điện thoại"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Mã GD:</label></b-col>
            <b-col>
              <b-form-input
                  v-model="searchData.order_code"
                  placeholder="Mã giao dịch"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`endDate`">Trạng thái :</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.status">
                <option value="">Chọn trạng thái</option>
                <option value="1">Thành công</option>
                <option value="0">Thất bại</option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row">
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row col-md-12 justify-content-end">
        <button
            ref="submit-search"
            class="
            btn btn-elevate btn-primary
            font-weight-bold
            px-11
            py-2
            my-4
            font-size-3
          "
            @click="search()"
        >
          Search
        </button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Lịch sử đổi Loyalty Urbox</h6>
              </template>
              <b-table
                  ref="table"
                  striped
                  hover
                  responsive
                  caption-top
                  :busy="tableLoading"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(stt)="data">
                  {{ data.index + 1 }}
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>...</strong>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import Message from "@/core/config/message.config";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const LoyaltyRepository = RepositoryFactory.get("loyalty");

export default {
  name: "ReportUrbox",
  mixins: [Common],
  components: {},
  data() {
    return {
      searchData: {
        order_code: '',
        phone: '',
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay()
      },
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "created_at",
          label: "Thời gian đổi quà"
        },
        {
          key: "order_code",
          label: "Mã GD",
          formatter: value => value.toLocaleString()
        },
        {
          key: "phone",
          label: "SDT",
          formatter: value => value.toLocaleString()
        },
        {
          key: "product_price",
          label: "Giá Voucher",
          formatter: value => value.toLocaleString()
        },
        {
          key: "product_name",
          label: "Tên Voucher",
          formatter: value => value.toLocaleString()
        },
        {
          key: "product_category",
          label: "Danh mục",
          formatter: value => value.toLocaleString()
        },
        {
          key: "status",
          label: "Trạng thái"
        }
      ],
      items: [],
      tableLoading: false,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0
      },
    };
  },
  created() {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Lịch sử đổi Urbox"}]);
    this.getDataReport();
  },
  watch: {
    currentPage() {
      this.getDataReport();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  methods: {
    async getDataReport() {
      this.items = [];
      let params = {
        page: this.currentPage,
        limit: this.paginate.limit,
      };

      if (this.searchData.order_code) {
        params.order_code = this.searchData.order_code;
      }
      if (this.searchData.phone) {
        params.phone = this.searchData.phone;
      }
      if (this.searchData.startDate) {
        params.start_date = this.searchData.startDate;
      }
      if (this.searchData.endDate) {
        params.end_date = this.searchData.endDate;
      }
      if (this.searchData.status) {
        params.status = this.searchData.status;
      }
      this.$bus.$emit("show-loading", true);
      LoyaltyRepository.getReportUrbox(params).then((response) => {
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items.push(...body.data.data);
        this.paginate.total = body.data.meta_data.total;
        this.paginate.totalPage = body.data.meta_data.last_page;
      }).catch(() => {
        return this.showError(Message.ERROR.SERVER);
      });
    },

    async search() {
      this.resetPaginate();
      await this.getDataReport();
    },

    resetPaginate() {
      this.currentPage = 1;
      this.paginate.limit = 25;
    }
  },
};
</script>
